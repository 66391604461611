'use client';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from "./LandingPage/components/button.tsx";
import { Input } from "./LandingPage/components/input.tsx";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "./LandingPage/components/card.tsx";

export default function Register() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [registrationCode, setRegistrationCode] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    try {
      const response = await fetch('https://lex0.com/auth/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          password,
          registration_code: registrationCode,
        }),
      });

      if (response.status === 201) {
        navigate('/login');
      } else {
        alert('Registration failed');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred during registration');
    }
  };


  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
      <Card className="w-full max-w-lg">
        <CardHeader>
          <CardTitle>Register</CardTitle>
          <CardDescription>Create an account by filling out the information below.</CardDescription>
        </CardHeader>
        <CardContent>
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div className="space-y-2">
              <label htmlFor="email" className="text-sm font-medium text-gray-700">
                Email
              </label>
              <Input
                id="email"
                onChange={(e) => setUsername(e.target.value)}
                type="email"
                placeholder="you@example.com"
                value={username}
                required
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="password" className="text-sm font-medium text-gray-700">
                Password
              </label>
              <Input
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder="Enter your password"
                value={password}
                required
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="confirmPassword" className="text-sm font-medium text-gray-700">
                Confirm Password
              </label>
              <Input
                id="confirmPassword"
                onChange={(e) => setConfirmPassword(e.target.value)}
                type="password"
                placeholder="Re-enter your password"
                value={confirmPassword}
                required
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="token" className="text-sm font-medium text-gray-700">
                Registration Token
              </label>
              <Input
                id="token"
                onChange={(e) => setRegistrationCode(e.target.value)}
                type="text"
                placeholder="Enter your registration token"
                value={registrationCode}
                required
              />
            </div>
            <Button type="submit" className="w-full">
              Register
            </Button>
          </form>
        </CardContent>
        <CardFooter className="flex justify-center">
          {/* Additional footer content can be added here if needed */}
        </CardFooter>
      </Card>
    </div>
  );
}