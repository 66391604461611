'use client'

import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import ProductSearch from './components/Search.tsx'
import Recommendations from './components/Recommendations.tsx'
import ProductDetails from '../Home/components/ProductDetails.tsx'
import { Product } from '../Home/types.tsx'
import { useNavigate } from 'react-router-dom'
import Header from '../../components/Navigation.tsx'

interface AskProps {
  setIsAuthenticated: (value: boolean) => void
}

interface Recommendation {
  item: string
  description: string
  products: Product[]
}

export default function Ask({ setIsAuthenticated }: AskProps) {
  const navigate = useNavigate()
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  })

  const [searchQuery, setSearchQuery] = useState('')
  const [recommendations, setRecommendations] = useState<Recommendation[]>([])
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const token = localStorage.getItem('authToken')
    if (!token) {
      setError('No authentication token found. Please log in.')
      navigate('/login')
    }
  }, [navigate])

  useEffect(() => {
    const fetchRecommendations = async () => {
      if (!searchQuery) return

      setIsLoading(true)
      setError(null)

      try {
        const token = localStorage.getItem('authToken')
        if (!token) {
          throw new Error('No authentication token found')
        }

        console.log('Fetching recommendations with search query:', searchQuery)

        const response = await fetch('https://lex0.com/symptomsearch/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ search_term: searchQuery })
        })

        if (!response.ok) {
          if (response.status === 401) {
            setError('Authentication failed. Please log in again.')
            localStorage.removeItem('authToken')
          } else {
            throw new Error('Failed to fetch recommendations')
          }
        }

        const data = await response.json()
        setRecommendations(data.recommendations)
      } catch (err) {
        setError('Failed to load recommendations. Please try again later.')
        console.error('Error fetching recommendations:', err)
      } finally {
        setIsLoading(false)
      }
    }

    fetchRecommendations()
  }, [searchQuery])

  const handleProductSelect = (product: Product) => {
    setSelectedProduct(product)
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-green-50 to-white relative">
      <Header setIsAuthenticated={setIsAuthenticated} />
      <motion.div
        ref={ref}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-6"
        initial={{ opacity: 0, y: 20 }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.8 }}
      >
        <h1 className="text-2xl font-bold text-gray-900 mb-2">Symptom Search</h1>
        <ProductSearch onSearch={setSearchQuery} isLoading={isLoading} />
        {isLoading && <p className="text-center text-gray-600 mt-8">Loading recommendations...</p>}
        {error && <p className="text-center text-red-600 mt-8">{error}</p>}
        {!isLoading && !error && recommendations.length > 0 && (
          <Recommendations
            recommendations={recommendations}
            onProductSelect={handleProductSelect}
          />
        )}
        {!isLoading && !error && recommendations.length === 0 && searchQuery && (
          <p className="text-center text-gray-600 mt-8">No recommendations found. Try a different search term.</p>
        )}
      </motion.div>
      {selectedProduct && (
        <ProductDetails
          product={selectedProduct}
          products={recommendations.flatMap(r => r.products)}
          onProductSelect={handleProductSelect}
          currentIndex={recommendations.flatMap(r => r.products).findIndex(p => p === selectedProduct)}
          onClose={() => setSelectedProduct(null)}
        />
      )}
    </div>
  )
}

