import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from "./button.tsx"
import { Menu, X } from 'lucide-react'

export default function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    return (
        <header className="py-4 px-4 sm:px-6 lg:px-8 bg-white/80 backdrop-blur-md fixed top-0 left-0 right-0 z-30">
            <div className="max-w-7xl mx-auto flex justify-between items-center">
                <Link to="/" className="text-2xl font-bold text-black-600 flex items-center">
                    LEXIOL
                </Link>
                
                <div className="hidden md:flex space-x-4">
                    <Link to="/login">
                        <Button variant="ghost">Log in</Button>
                    </Link>
                    <Link to='/demo'>
                        <Button>Book a Demo</Button>
                    </Link>
                </div>
                <button className="md:hidden" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    {isMenuOpen ? <X /> : <Menu />}
                </button>
            </div>
            {isMenuOpen && (
                <div className="md:hidden mt-4">
                    <nav className="flex flex-col space-y-4">
                        
                        <Link to="/login">
                            <Button variant="ghost" className="w-full">Log in</Button>
                        </Link>
                        <Button className="w-full">Book a demo</Button>
                    </nav>
                </div>
            )}
        </header>
    )
}
