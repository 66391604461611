'use client'

import React, { useState } from 'react'
import { Button } from "./LandingPage/components/button.tsx"
import { Input } from "./LandingPage/components/input.tsx"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "./LandingPage/components/card.tsx"

export default function Demo() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [company, setCompany] = useState('')
  const [questions, setQuestions] = useState('')

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    const data = { name, email, phone, company, questions }

    try {
      const response = await fetch('https://lex0.com/demo', { 
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      })

      if (!response.ok) {
        console.error('Error sending demo request')
        // Optionally handle error feedback to the user here
      } else {
        console.log('Demo request sent successfully')
        // Optionally handle success feedback to the user here
      }
    } catch (error) {
      console.error('Network error:', error)
      // Optionally handle network error feedback to the user here
    }

    // Reset form fields after submission
    setName('')
    setEmail('')
    setPhone('')
    setCompany('')
    setQuestions('')
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
      <Card className="w-full max-w-lg">
        <CardHeader>
          <CardTitle>Book a Demo</CardTitle>
          <CardDescription>Fill out the form below to schedule a demo with our team.</CardDescription>
        </CardHeader>
        <CardContent>
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div className="space-y-2">
              <label htmlFor="name" className="text-sm font-medium text-gray-700">
                Name
              </label>
              <Input
                id="name"
                onChange={(e) => setName(e.target.value)}
                type="text"
                placeholder="Your full name"
                value={name}
                required
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="email" className="text-sm font-medium text-gray-700">
                Email
              </label>
              <Input
                id="email"
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="you@example.com"
                value={email}
                required
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="phone" className="text-sm font-medium text-gray-700">
                Phone Number
              </label>
              <Input
                id="phone"
                onChange={(e) => setPhone(e.target.value)}
                type="tel"
                placeholder="+1 (555) 123-4567"
                value={phone}
                required
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="company" className="text-sm font-medium text-gray-700">
                Company Name
              </label>
              <Input
                id="company"
                onChange={(e) => setCompany(e.target.value)}
                type="text"
                placeholder="Your company name"
                value={company}
                required
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="questions" className="text-sm font-medium text-gray-700">
                Questions
              </label>
              <textarea
                id="questions"
                onChange={(e) => setQuestions(e.target.value)}
                placeholder="Any specific questions or topics you'd like to cover?"
                value={questions}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-2"
                rows={4}
              ></textarea>
            </div>
            <Button type="submit" className="w-full">
              Book Demo
            </Button>
          </form>
        </CardContent>
        <CardFooter className="flex justify-center">
          {/* Additional footer content if needed */}
        </CardFooter>
      </Card>
    </div>
  )
}
