import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LandingPageComponent from './pages/LandingPage/LandingPage.tsx';
import './index.css';
import './global.css';
import LoginPage from './pages/Login.tsx';
import Demo from './pages/Demo.tsx';
import Register from './pages/Register.tsx';
import Home from './pages/Home/Home.tsx';
import ProtectedRoute from './components/ProtectedRoute.tsx';
import Ask from './pages/SymptomSearch/Ask.tsx';
import Cart from './pages/Cart/Cart.tsx';
import Pricing from './pages/Pricing/Pricing.tsx';

export default function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const checkTokenValidity = (token: string) => {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      const expirationTime = payload.exp * 1000; // Convert to milliseconds
      return Date.now() < expirationTime;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token && checkTokenValidity(token)) {
      setIsAuthenticated(true);
    } else {
      localStorage.removeItem('authToken'); // Remove expired token
      setIsAuthenticated(false);
    }
    setIsLoading(false);
  }, []);

  const setAuthToken = (token: string) => {
    if (checkTokenValidity(token)) {
      localStorage.setItem('authToken', token);
      setIsAuthenticated(true);
    } else {
      localStorage.removeItem('authToken');
      setIsAuthenticated(false);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPageComponent />} />
        <Route path="/login" element={
          isAuthenticated ? <Navigate to="/home" /> : <LoginPage setAuthToken={setAuthToken} />
        } />
        <Route path="/register" element={<Register />} />
        <Route path="/demo" element={<Demo />} />
        <Route
          path="/home"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <Home setIsAuthenticated={setIsAuthenticated} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ask"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <Ask setIsAuthenticated={setIsAuthenticated}/>
            </ProtectedRoute>
          }
        />
        <Route
          path="/cart"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <Cart setIsAuthenticated={setIsAuthenticated}/>
            </ProtectedRoute>
          }
        />
        <Route
          path="/pricing"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <Pricing setIsAuthenticated={setIsAuthenticated} />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}