import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from "./LandingPage/components/button.tsx";
import { Input } from "./LandingPage/components/input.tsx";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "./LandingPage/components/card.tsx";
import { Link } from 'react-router-dom';

export default function LoginPage({ setAuthToken }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await fetch('https://lex0.com/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          password,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        const token = data.token;
        setAuthToken(token);
        localStorage.setItem('authToken', token); // Store token securely
        navigate('/home');
      } else {
        alert('Login failed');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred during login');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
      <Card className="w-full max-w-lg">
        <CardHeader>
          <CardTitle>Login</CardTitle>
          <CardDescription>Login to your account</CardDescription>
        </CardHeader>
        <CardContent>
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div className="space-y-2">
              <label htmlFor="email" className="text-sm font-medium text-gray-700">
                Email
              </label>
              <Input
                id="email"
                onChange={(e) => setUsername(e.target.value)}
                type="email"
                placeholder="you@example.com"
                value={username}
                required
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="password" className="text-sm font-medium text-gray-700">
                Password
              </label>
              <Input
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder="Enter your password"
                value={password}
                required
              />
            </div>
            <Button type="submit" className="w-full">
              Login
            </Button>
          </form>
        </CardContent>
        <CardFooter className="flex justify-center">
            <p className="text-sm text-gray-600">
            Have your Lexiol Token?{' '}
            <Link to="/register" className="text-blue-600 hover:underline">
              Register
            </Link>
            </p>
        </CardFooter>
      </Card>
    </div>
  );
}