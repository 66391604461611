'use client'
import React from 'react'

import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "./card.tsx"
import { ArrowTrendingUpIcon, ArrowTrendingDownIcon } from '@heroicons/react/24/solid/index.js'

export default function PredictionCard() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  })

  return (
    <section ref={ref} className="py-20 px-4 sm:px-6 lg:px-8">
      <motion.h2
              className="text-3xl sm:text-4xl font-bold text-center text-gray-900 mb-12"
              initial={{ opacity: 0, y: 50 }}
              animate={inView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.8 }}
            >
              Predictive Pricing Recomendations
            </motion.h2>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={inView ? { opacity: 1, y: 1 } : {}}
        transition={{ duration: 0.8 }}
        className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto"
      >
        <Card>
          <CardHeader>
            <CardTitle>RX Price Prediction</CardTitle>
            <CardDescription>Forecast for prescription medication prices</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="flex items-center justify-between">
              <span className="text-2xl font-bold text-green-600">+2.5%</span>
              <ArrowTrendingUpIcon className="w-8 h-8 text-green-600" />
            </div>
            <p className="mt-2 text-sm text-gray-600">Expected increase in the next quarter</p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>OTC Price Prediction</CardTitle>
            <CardDescription>Forecast for over-the-counter medication prices</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="flex items-center justify-between">
              <span className="text-2xl font-bold text-red-600">-1.2%</span>
              <ArrowTrendingDownIcon className="w-8 h-8 text-red-600" />
            </div>
            <p className="mt-2 text-sm text-gray-600">Expected decrease in the next quarter</p>
          </CardContent>
        </Card>
      </motion.div>
    </section>
  )
}

