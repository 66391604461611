import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';
import { Button } from "../../LandingPage/components/button.tsx";
import { Product } from '../../Home/types.tsx';

interface PricingProductDetailsProps {
    product: PricingProduct;  // Change this from Product to PricingProduct
    onClose: () => void;
    products: PricingProduct[];  // Change this from Product[] to PricingProduct[]
    onProductSelect: (product: PricingProduct) => void;  // Change this from Product to PricingProduct
    currentIndex: number;
  }
  
interface PricingProduct extends Product {
    quantity: number;
    upc: string;
  }

const ImageCarousel = ({ images }: { images: string[] }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  
  const nextImage = () => {
    setCurrentImageIndex((prev) => (prev + 1) % images.length);
  };
  
  const previousImage = () => {
    setCurrentImageIndex((prev) => (prev - 1 + images.length) % images.length);
  };

  return (
    <div className="relative bg-gray-100 rounded-lg overflow-hidden group">
      {/* Container with fixed dimensions */}
      <div className="w-[500px] h-[300px] mx-auto relative">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentImageIndex}
            className="absolute inset-0 flex items-center justify-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <img
              src={images[currentImageIndex]}
              alt={`Product view ${currentImageIndex + 1}`}
              className="max-w-full max-h-full object-contain"
              style={{
                maxWidth: '500px',
                maxHeight: '300px',
                width: 'auto',
                height: 'auto'
              }}
            />
          </motion.div>
        </AnimatePresence>
      
        {images.length > 1 && (
          <>
            {/* Navigation Arrows */}
            <button
              onClick={previousImage}
              className="absolute left-2 top-1/2 -translate-y-1/2 bg-black/50 hover:bg-black/70 text-white p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
              aria-label="Previous image"
            >
              <ChevronLeft className="h-6 w-6" />
            </button>
            <button
              onClick={nextImage}
              className="absolute right-2 top-1/2 -translate-y-1/2 bg-black/50 hover:bg-black/70 text-white p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
              aria-label="Next image"
            >
              <ChevronRight className="h-6 w-6" />
            </button>
            
            {/* Image Counter */}
            <div className="absolute bottom-4 left-1/2 -translate-x-1/2 bg-black/50 text-white px-3 py-1 rounded-full text-sm">
              {currentImageIndex + 1} / {images.length}
            </div>
            
            {/* Dot indicators */}
            <div className="absolute bottom-4 left-0 right-0 flex justify-center gap-2">
              {images.map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentImageIndex(index)}
                  className={`w-2 h-2 rounded-full transition-all ${
                    index === currentImageIndex 
                      ? 'bg-white scale-125' 
                      : 'bg-white/50 hover:bg-white/75'
                  }`}
                  aria-label={`Go to image ${index + 1}`}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default function PricingProductDetails({ 
  product, 
  onClose, 
  products, 
  onProductSelect,
  currentIndex 
}: PricingProductDetailsProps) {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        if (currentIndex < products.length - 1) {
          onProductSelect(products[currentIndex + 1]);
        }
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        if (currentIndex > 0) {
          onProductSelect(products[currentIndex - 1]);
        }
      } else if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [currentIndex, products, onProductSelect, onClose]);

  return (
    <AnimatePresence>
      <div className="fixed inset-0 z-50 flex justify-end">
        <motion.div
          className="absolute inset-0 bg-black"
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.3 }}
          exit={{ opacity: 0 }}
          onClick={onClose}
        />
        
        <motion.div
          className="w-3/5 bg-white h-full shadow-xl relative"
          initial={{ x: '100%' }}
          animate={{ x: 0 }}
          exit={{ x: '100%' }}
          transition={{ type: 'spring', damping: 30, stiffness: 300 }}
        >
          <div className="flex justify-between items-center p-6 border-b sticky top-0 bg-white z-10">
            <h2 className="text-2xl font-bold text-gray-900">{product.title}</h2>
            <Button variant="ghost" onClick={onClose}>
              <X className="h-6 w-6" />
            </Button>
          </div>

          <div className="p-6 overflow-y-auto h-[calc(100vh-5rem)]">
            <div className="mb-6">
              {product.images && product.images.length > 0 ? (
                <ImageCarousel images={product.images} />
              ) : (
                <div className="w-[500px] h-[300px] mx-auto bg-gray-100 rounded-lg flex items-center justify-center">
                  <span className="text-gray-400">No image available</span>
                </div>
              )}
            </div>

            {/* Rest of the component remains the same */}
            <div className="grid grid-cols-1 gap-6 mb-6">
              <div>
                <h3 className="text-lg font-semibold mb-2">Basic Information</h3>
                <p className="mb-1"><strong>UPC:</strong> {product.upc}</p>
                <p className="mb-1"><strong>Item Number:</strong> {product.item_number}</p>
                <p className="mb-1"><strong>Brand:</strong> {product.brand}</p>
              </div>
              
              <div>
                <h3 className="text-lg font-semibold mb-2">Specifications</h3>
                <p className="mb-1"><strong>Category:</strong> {product.category}</p>
                <p className="mb-1"><strong>Color:</strong> {product.color}</p>
                <p className="mb-1"><strong>Size:</strong> {product.size}</p>
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-4">Available Offers</h3>
                <div className="overflow-hidden rounded-lg border border-gray-200">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Vendor
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Price
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {product.offers
                        ?.sort((a, b) => {
                          const priorityOrder = ["CVS", "Walgreens", "Target", "Wal-Mart.com"];
                          const aIndex = priorityOrder.indexOf(a.merchant);
                          const bIndex = priorityOrder.indexOf(b.merchant);
                          if (aIndex === -1 && bIndex === -1) return 0;
                          if (aIndex === -1) return 1;
                          if (bIndex === -1) return -1;
                          return aIndex - bIndex;
                        })
                        .map((offer, index) => (
                          <tr key={index} className="hover:bg-gray-50">
                            <td className="px-6 py-4 whitespace-nowrap">
                              <a
                                href={offer.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-600 hover:text-blue-800 hover:underline"
                              >
                                {offer.merchant}
                              </a>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-right">
                              ${offer.price}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-2">Description</h3>
                <p className="text-gray-700">{product.description}</p>
              </div>
            </div>

            <div className="mt-4 flex justify-between text-sm text-gray-500">
              <p>Use ↑↓ arrows to navigate products</p>
              <p>ESC to close</p>
            </div>
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
}
