'use client'
import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Navigation.tsx';
import PricingItem from './components/PricingItem.tsx';
import { Product } from '../Home/types.tsx';
import PricingProductDetails from './components/PricingProductDetails.tsx'; // Add this import

interface PricingProps {
  setIsAuthenticated: (value: boolean) => void;
}

interface PricingProduct extends Product {
  quantity: number;
  new_price?: number;
  upc: string;
}

export default function Pricing({ setIsAuthenticated }: PricingProps) {
  const navigate = useNavigate();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
// Add these two new state variables
  const [selectedProduct, setSelectedProduct] = useState<PricingProduct | null>(null);
  const [selectedProductIndex, setSelectedProductIndex] = useState<number>(-1);
  const [pricingItems, setPricingItems] = useState<PricingProduct[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isPdfLoading, setIsPdfLoading] = useState(false);

  const handlePrintPricingSheet = async () => {
    setIsPdfLoading(true);
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await fetch('https://lex0.com/pricing/print', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to generate PDF');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'pricing-sheet.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      setError('Failed to generate PDF. Please try again later.');
      console.error('Error generating PDF:', err);
    } finally {
      setIsPdfLoading(false);
    }
  };

  const handleProductSelect = (product: PricingProduct) => {
    const index = pricingItems.findIndex(item => item.upc === product.upc);
    setSelectedProduct(product);
    setSelectedProductIndex(index);
  };

  const fetchPricingItems = useCallback(async () => {
    setIsLoading(true);
    setError(null);
  
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        throw new Error('No authentication token found');
      }
  
      const response = await fetch('https://lex0.com/pricing/', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (!response.ok) {
        if (response.status === 401) {
          setError('Authentication failed. Please log in again.');
          localStorage.removeItem('authToken');
          navigate('/login');
          return;
        } else {
          throw new Error('Failed to fetch Pricing items');
        }
      }
  
      const data = await response.json();
      const flattened = data.map((item: any) => ({
        ...item.product,
        quantity: item.quantity,
        upc: item.upc,
        new_price: item.new_price
      }));
      setPricingItems(flattened);
    } catch (err) {
      setError('Failed to load Pricing items. Please try again later.');
      console.error('Error fetching Pricing items:', err);
    } finally {
      setIsLoading(false);
    }
  }, [navigate]);

  const handleUpdate = async (upc: string, updates: { quantity?: number; new_price?: number }) => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        throw new Error('No authentication token found');
      }

      const currentItem = pricingItems.find(item => item.upc === upc);
      if (!currentItem) {
        throw new Error('Item not found');
      }

      const updateData = {
        upc,
        quantity: updates.quantity ?? currentItem.quantity,
        new_price: updates.new_price ?? currentItem.new_price ?? 0
      };

      const response = await fetch('https://lex0.com/pricing/update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(updateData)
      });

      if (!response.ok) {
        throw new Error('Failed to update item');
      }

      setPricingItems(prevItems =>
        prevItems.map(item =>
          item.upc === upc ? { ...item, ...updates } : item
        )
      );
    } catch (err) {
      setError('Failed to update item. Please try again later.');
      console.error('Error updating item:', err);
    }
  };

  const handleRemoveItem = async (upc: string) => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await fetch('https://lex0.com/pricing/delete', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ upc })
      });

      if (!response.ok) {
        throw new Error('Failed to remove item from Pricing');
      }

      setPricingItems(prevItems => prevItems.filter(item => item.upc !== upc));
    } catch (err) {
      setError('Failed to remove item. Please try again later.');
      console.error('Error removing item:', err);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No authentication token found. Please log in.');
      navigate('/login');
      return;
    }
    fetchPricingItems();
  }, [fetchPricingItems, navigate]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white relative">
      <Header setIsAuthenticated={setIsAuthenticated} />
      <motion.div
        ref={ref}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-6"
        initial={{ opacity: 0, y: 20 }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.8 }}
      >
        <h1 className="text-2xl font-bold text-gray-900 mb-6">Pricing</h1>
        
        {isLoading && <p className="text-center text-gray-600 mt-8">Loading Pricing items...</p>}
        {error && <p className="text-center text-red-600 mt-8">{error}</p>}

        {!isLoading && !error && pricingItems.length > 0 && (
          <div className="flex justify-end mb-4">
            <button 
              onClick={handlePrintPricingSheet}
              disabled={isPdfLoading}
              className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${
                isPdfLoading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {isPdfLoading ? 'Generating PDF...' : 'Print Pricing Sheet'}
            </button>
          </div>
        )}
        
        {!isLoading && !error && pricingItems.length === 0 && (
          <div className="text-center text-gray-600 mt-8">
            <p>Your Pricing is empty</p>
            <button 
              onClick={() => navigate('/home')}
              className="mt-4 px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
            >
              Add Items
            </button>
          </div>
        )}

        {!isLoading && !error && pricingItems.length > 0 && (
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Image
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Brand
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Quantity
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Price
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                <AnimatePresence>
                {pricingItems.map((item) => (
                    <PricingItem
                      key={item.upc}
                      product={{
                        ...item,
                        new_price: item.new_price // Make sure this is being passed
                      }}
                      quantity={item.quantity}
                      onUpdate={handleUpdate}
                      onRemove={handleRemoveItem}
                      onSelect={handleProductSelect}
                    />
                  ))}
                </AnimatePresence>
              </tbody>
            </table>
          </div>
        )}
        {/* Add PricingProductDetails component here */}
        {selectedProduct && (
          <PricingProductDetails
            product={selectedProduct}
            onClose={() => setSelectedProduct(null)}
            products={pricingItems}
            onProductSelect={handleProductSelect}
            currentIndex={selectedProductIndex}
          />
        )}
      </motion.div>
    </div>
  );
}
     