'use client'
import React from 'react'

import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import SineWaveBackground from './SineWaveBackground.tsx'
import { Button } from "./button.tsx"
import { Link } from 'react-router-dom'

export default function Hero() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  })

  return (
    <section ref={ref} className="min-h-screen flex items-center justify-center px-4 sm:px-6 lg:px-8 relative overflow-hidden pt-20">
      <SineWaveBackground />
      <div className="text-center relative z-10 max-w-4xl mx-auto">
        <motion.h1
          className="text-4xl sm:text-5xl lg:text-6xl font-bold text-gray-900 mb-6 leading-tight"
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          The Future of<br />
          <span className="text-blue-600">Pharmacy Management</span>
        </motion.h1>
        <motion.p
          className="text-xl sm:text-2xl text-gray-600 mb-8"
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          Spend more time filling prescriptions, we'll handle the rest
        </motion.p>
        <motion.div
          className="flex justify-center"
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.8, delay: 0.6 }}
        >
          <Link to='/demo'>
                     <Button size="lg" className="px-8 py-3 text-lg">Book a Demo</Button>  
           
          </Link>
          
        </motion.div>
      </div>
    </section>
  )
}

