'use client'
import React from 'react'

import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { DollarSign, Users, Search, BarChart2 } from 'lucide-react'

const features = [
  {
    icon: DollarSign,
    title: "Quick Price Check",
    description: "Instantly compare OTC prices with competitors to stay competitive.",
  },
  {
    icon: Users,
    title: "Rapid Staff Onboarding",
    description: "Efficiently train new staff with our intuitive product recommendation system.",
  },
  {
    icon: Search,
    title: "Symptom-Based Search",
    description: "Quickly find suitable products by entering patient symptoms and conditions.",
  },
  {
    icon: BarChart2,
    title: "Inventory & Price Analysis",
    description: "Manage inventory and analyze price trends to optimize your pharmacy operations.",
  },
]

export default function Features() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  })

  return (
    <section id="features" ref={ref} className="py-20 px-4 sm:px-6 lg:px-8">
      <motion.h2
        className="text-3xl sm:text-4xl font-bold text-center text-gray-900 mb-12"
        initial={{ opacity: 0, y: 50 }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.8 }}
      >
        Powerful Features
      </motion.h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {features.map((feature, index) => (
            <motion.div
            key={index}
            className="bg-white p-6 rounded-lg shadow-lg"
            initial={{ scale: 0.8, y: 50 }}
            animate={inView ? { scale: 1, y: 1 } : {}}
            transition={{ duration: 0.8, delay: index * 0.2 }}
            >
            <feature.icon className="w-12 h-12 text-blue-600 mb-4" />
            <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
            <p className="text-gray-600">{feature.description}</p>
          </motion.div>
        ))}
      </div>
    </section>
  )
}

