'use client'
import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Navigation.tsx';
import CartItem from './components/Cartitem.tsx';
import { Product } from '../Home/types.tsx';

interface CartProps {
  setIsAuthenticated: (value: boolean) => void;
}

interface CartProduct extends Product {
  quantity: number;
}

export default function Cart({ setIsAuthenticated }: CartProps) {
  const navigate = useNavigate();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [cartItems, setCartItems] = useState<CartProduct[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchCartItems = useCallback(async () => {
    setIsLoading(true);
    setError(null);
  
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        throw new Error('No authentication token found');
      }
  
      const response = await fetch('https://lex0.com/cart/', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (!response.ok) {
        if (response.status === 401) {
          setError('Authentication failed. Please log in again.');
          localStorage.removeItem('authToken');
          navigate('/login');
          return;
        } else {
          throw new Error('Failed to fetch cart items');
        }
      }
  
      const data = await response.json();
      const flattened = data.map((item: any) => ({
        ...item.product,
        quantity: item.quantity,
        upc: item.upc,
      }));
      setCartItems(flattened);
    } catch (err) {
      setError('Failed to load cart items. Please try again later.');
      console.error('Error fetching cart items:', err);
    } finally {
      setIsLoading(false);
    }
  }, [navigate]);
  

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No authentication token found. Please log in.');
      navigate('/login');
      return;
    }
    fetchCartItems();
  }, [fetchCartItems, navigate]);

  const handleUpdateQuantity = async (upc: string, quantity: number) => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await fetch('https://lex0.com/cart/update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ upc, quantity })
      });

      if (!response.ok) {
        throw new Error('Failed to update cart');
      }

      setCartItems(prevItems =>
        prevItems.map(item =>
          item.upc === upc ? { ...item, quantity } : item
        )
      );
    } catch (err) {
      setError('Failed to update cart. Please try again later.');
      console.error('Error updating cart:', err);
    }
  };

  const handleRemoveItem = async (upc: string) => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await fetch('https://lex0.com/cart/delete', {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ upc })
      });

      if (!response.ok) {
        throw new Error('Failed to remove item from cart');
      }

      setCartItems(prevItems => prevItems.filter(item => item.upc !== upc));
    } catch (err) {
      setError('Failed to remove item. Please try again later.');
      console.error('Error removing item:', err);
    }
  };

  // Handler to copy all item_numbers to the clipboard
  const handleCopyToClipboard = () => {
    const numbers = cartItems
      .map(item => item.item_number)
      .filter(Boolean); // Filter out any undefined or null values

    // Format numbers: add a comma to each except the last
    const lines = numbers.map((num, idx) =>
      idx < numbers.length - 1 ? `${num},` : `${num}`
    );
    const textToCopy = lines.join('\n');

    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        alert('Copied to clipboard!');
      })
      .catch(err => {
        console.error('Error copying to clipboard', err);
        alert('Failed to copy to clipboard');
      });
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white relative">
      <Header setIsAuthenticated={setIsAuthenticated} />
      <motion.div
        ref={ref}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-6"
        initial={{ opacity: 0, y: 20 }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.8 }}
      >
        <h1 className="text-2xl font-bold text-gray-900 mb-6">Shopping Cart</h1>
        
        {isLoading && <p className="text-center text-gray-600 mt-8">Loading cart items...</p>}
        {error && <p className="text-center text-red-600 mt-8">{error}</p>}

        {/* Render Copy To Kinray button if there are items */}
        {!isLoading && !error && cartItems.length > 0 && (
          <div className="flex justify-end mb-4">
            <button 
              onClick={handleCopyToClipboard} 
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Copy To Kinray
            </button>
          </div>
        )}
        
        {!isLoading && !error && cartItems.length === 0 && (
          <div className="text-center text-gray-600 mt-8">
            <p>Your cart is empty</p>
            <button
              onClick={() => navigate('/home')}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
            >
              Continue Shopping
            </button>
          </div>
        )}

        {!isLoading && !error && cartItems.length > 0 && (
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Image
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Brand
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Quantity
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                <AnimatePresence>
                  {cartItems.map((item) => (
                    <CartItem
                      key={item.upc}
                      product={item}
                      quantity={item.quantity}
                      onUpdateQuantity={handleUpdateQuantity}
                      onRemove={handleRemoveItem}
                    />
                  ))}
                </AnimatePresence>
              </tbody>
            </table>
          </div>
        )}
      </motion.div>
    </div>
  );
}
