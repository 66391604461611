import { useState } from 'react'
import { Input } from "../../LandingPage/components/input.tsx"
import { Button } from "../../LandingPage/components/button.tsx"
import { Search } from 'lucide-react'
import React from 'react'

interface ProductSearchProps {
    onSearch: (query: string) => void
    isLoading: boolean
  }
  
  export default function ProductSearch({ onSearch, isLoading }: ProductSearchProps) {
    const [inputValue, setInputValue] = useState('')
  
    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault()
      onSearch(inputValue.trim())
    }
  
    return (
      <form onSubmit={handleSubmit} className="flex items-center mb-8">
        <Input
          type="text"
          placeholder="Search by Name, UPC, or Item Number..."
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className="flex-grow mr-2"
          disabled={isLoading}
        />
        <Button type="submit" disabled={isLoading}>
          {isLoading ? (
            <span className="loader"></span>
          ) : (
            <>
              <Search className="w-5 h-5 mr-2" />
              Search
            </>
          )}
        </Button>
      </form>
    )
  }

