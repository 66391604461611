import React from 'react';
import { motion } from 'framer-motion';
import ProductImage from '../../Home/components/ProductImage.tsx';
import { Product } from '../../Home/types.tsx';

interface CartItemProps {
  product: Product;
  quantity: number;
  onUpdateQuantity: (upc: string, quantity: number) => void;
  onRemove: (upc: string) => void;
}

export default function CartItem({ product, quantity, onUpdateQuantity, onRemove }: CartItemProps) {
  return (
    <motion.tr
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, x: -50 }}  
      transition={{ duration: 0.3 }}
      className="hover:bg-gray-50"
    >
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="w-16 h-16 flex items-center justify-center">
          {product.images ? (
            <ProductImage images={product.images} alt={product.title} />
          ) : (
            <div className="h-16 w-16 bg-gray-200 flex items-center justify-center text-gray-500">
              N/A
            </div>
          )}
        </div>
      </td>
      <td className="px-6 py-4 whitespace-normal break-words w-2/3">
        {product.title}
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        {product.brand || 'Unknown'}
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <input
          type="number"
          min="1"
          value={quantity}
          onChange={(e) => onUpdateQuantity(product.upc, Number(e.target.value))}
          className="w-16 text-center border border-gray-300 rounded"
        />
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <button
          onClick={() => onRemove(product.upc)}
          className="px-3 py-1 text-sm bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
        >
          Remove
        </button>
      </td>
    </motion.tr>
  );
}
