import React from "react"



interface ChartConfig {

  [key: string]: {

    label: string

    color: string

  }

}



interface ChartContainerProps {

  children: React.ReactNode

  config: ChartConfig

  className?: string

}



export const ChartContainer: React.FC<ChartContainerProps> = ({

  children,

  config,

  className,

}) => {

  return <div className={className}>{children}</div>

}



export const ChartTooltip = ({ content, ...props }: any) => {

  return <>{content}</>

}



export const ChartTooltipContent = ({ active, payload, label }: any) => {

  if (!active || !payload) return null

  return (

    <div className="rounded-lg border bg-background p-2 shadow-sm">

      <div className="grid grid-cols-2 gap-2">

        <div className="flex flex-col">

          <span className="text-[0.70rem] uppercase text-muted-foreground">

            {label}

          </span>

          <span className="font-bold text-muted-foreground">

            ${payload[0]?.value}

          </span>

        </div>

      </div>

    </div>

  )

}
