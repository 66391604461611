'use client'
import React from 'react'
import { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { Search } from 'lucide-react'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "./card.tsx"
import { Input } from "./input.tsx"
import { Button } from "./button.tsx"
import ibuprofenImage from "../assets/advil.jpg"
import acetaminophenImage from "../assets/tylenol.jpg"
import aspirinImage from "../assets/aspirin.png"

const imageStyle = {
  width: '100%',
  height: '200px', // Set a fixed height
  objectFit: 'contain' // This ensures the image is fully visible without cropping
} as const;

const recommendations = [
  {
    title: "Ibuprofen",
    description: "This nonsteroidal anti-inflammatory drug (NSAID) can help reduce pain and inflammation associated with migraines.",
    image: ibuprofenImage,
    style: imageStyle
  },
  {
    title: "Acetaminophen",
    description: "This pain reliever can be effective for moderate migraine pain. It can be an option for those who cannot take NSAIDs.",
    image: acetaminophenImage,
    style: imageStyle
  },
  {
    title: "Aspirin",
    description: "This NSAID can help relieve migraine pain. Studies suggest that taking aspirin with acetaminophen may enhance pain relief.",
    image: aspirinImage,
    style: imageStyle
  }
]

export default function SymptomSearch() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  })

  const [showResults, setShowResults] = useState(false)

  useEffect(() => {
    if (inView) {
      setShowResults(true)
    }
  }, [inView])

  return (
    <section ref={ref} className="py-20 px-4 sm:px-6 lg:px-8 bg-gray-50">
      <motion.h2
        className="text-3xl sm:text-4xl font-bold text-center text-gray-900 mb-12"
        initial={{ opacity: 0, y: 50 }}
        animate={inView ? { opacity: 1, y: 1 } : {}}
        transition={{ duration: 0.8 }}
      >
        Symptom-Based Product Search
      </motion.h2>
      <motion.div
        className="max-w-3xl mx-auto"
        initial={{ opacity: 0, y: 50 }}
        animate={inView ? { opacity: 1, y: 1 } : {}}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        <form className="mb-8">
          <div className="relative">
            <Input
              type="text"
              value="migraine"
              readOnly
              className="w-full px-4 py-3 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
            />
            <Button
              type="submit"
              className="absolute right-3 top-1/2 transform -translate-y-1/2 bg-blue-600 text-white p-2 rounded-full hover:bg-blue-700 transition duration-300"
            >
              <Search className="w-5 h-5" />
            </Button>
          </div>
        </form>
        {showResults && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Card className="mb-8">
              <CardHeader>
                <CardTitle>Recommendations for Migraine</CardTitle>
                <CardDescription>
                  Migraines can be quite debilitating, and while it's always best to consult with a healthcare professional for persistent or severe symptoms, there are several over-the-counter (OTC) options that may help alleviate migraine pain. Here are a few recommendations:
                </CardDescription>
              </CardHeader>
            </Card>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {recommendations.map((item, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <Card>
                    <CardHeader>
                      <CardTitle>{item.title}</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <img src={item.image} alt={item.title} className="w-full h-32 object-cover mb-4 rounded-md" />
                      <CardDescription>{item.description}</CardDescription>
                    </CardContent>
                  </Card>
                </motion.div>
              ))}
            </div>
          </motion.div>
        )}
      </motion.div>
    </section>
  )
}

