import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import ProductImage from './ProductImage.tsx';
import { Product } from '../types.tsx';
import { ShoppingCart, Calculator } from 'lucide-react';

interface ProductListProps {
  products: Product[];
  onProductSelect: (product: Product) => void;
}

export default function ProductList({ products, onProductSelect }: ProductListProps) {
  const [quantities, setQuantities] = useState<Record<string, number>>(
    products.reduce((acc, product) => {
      acc[product.upc] = 1;
      return acc;
    }, {} as Record<string, number>)
  );

  const [notification, setNotification] = useState<{
    show: boolean;
    message: string;
    type: 'success' | 'error';
  }>({ show: false, message: '', type: 'success' });

  const handleQuantityChange = (upc: string, value: number) => {
    setQuantities((prev) => ({
      ...prev,
      [upc]: value,
    }));
  };

  const showNotification = (message: string, type: 'success' | 'error') => {
    setNotification({ show: true, message, type });
    setTimeout(() => setNotification({ show: false, message: '', type: 'success' }), 3000);
  };

  const handleAddToCart = async (upc: string) => {
    const quantity = quantities[upc] > 0 ? quantities[upc] : 1;

    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await fetch('https://lex0.com/cart/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ upc, quantity }),
      });

      if (!response.ok) {
        throw new Error('Failed to add item to cart');
      }

      showNotification('Added to Cart!', 'success');
    } catch (error: any) {
      console.error('Error adding item to cart:', error.message);
      showNotification(error.message, 'error');
    }
  };

  const handleAddToPricing = async (upc: string) => {
    const quantity = quantities[upc] > 0 ? quantities[upc] : 1;

    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await fetch('https://lex0.com/pricing/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ upc, quantity }),
      });

      if (!response.ok) {
        throw new Error('Failed to add item to pricing');
      }

      showNotification('Added to Pricing!', 'success');
    } catch (error: any) {
      console.error('Error adding item to pricing:', error.message);
      showNotification(error.message, 'error');
    }
  };

  return (
    <div className="relative">
      {/* Notification Popup */}
      <AnimatePresence>
        {notification.show && (
          <motion.div 
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className={`fixed top-4 right-4 px-4 py-2 rounded shadow-md z-50 ${
              notification.type === 'success' ? 'bg-green-500' : 'bg-red-500'
            } text-white`}
          >
            {notification.message}
          </motion.div>
        )}
      </AnimatePresence>

      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200 table-fixed">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Image
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Brand
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                UPC
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Item Number
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {products.map((product, index) => (
              <motion.tr
                key={product.upc}
                onClick={() => onProductSelect(product)}
                className="cursor-pointer hover:bg-gray-50"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: index * 0.05 }}
              >
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="w-16 h-16 flex items-center justify-center">
                    {product.images ? (
                      <ProductImage images={product.images} alt={product.title} />
                    ) : (
                      <div className="h-16 w-16 bg-gray-200 flex items-center justify-center text-gray-500">
                        N/A
                      </div>
                    )}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-normal break-words w-2/3">
                  {product.title}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {product.brand || 'Unknown'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {product.upc}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {product.item_number}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center space-x-2" onClick={(e) => e.stopPropagation()}>
                    <input
                      type="number"
                      min="1"
                      value={quantities[product.upc]}
                      onChange={(e) => handleQuantityChange(product.upc, Number(e.target.value))}
                      className="w-16 text-center border border-gray-300 rounded p-1"
                    />
                    <button
                      onClick={() => handleAddToCart(product.upc)}
                      className="px-3 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors flex items-center space-x-1"
                    >
                      <ShoppingCart size={16} />
                      <span>Cart</span>
                    </button>
                    <button
                      onClick={() => handleAddToPricing(product.upc)}
                      className="px-3 py-2 bg-purple-500 text-white rounded hover:bg-purple-600 transition-colors flex items-center space-x-1"
                    >
                      <Calculator size={16} />
                      <span>Price</span>
                    </button>
                  </div>
                </td>
              </motion.tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}