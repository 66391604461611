'use client'
import React from 'react'

import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { Line, LineChart, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer } from 'recharts'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "./card.tsx"
import { ChartContainer, ChartTooltip, ChartTooltipContent } from "./chart.tsx"

const data = [
  { month: 'Jan', price: 100 },
  { month: 'Feb', price: 120 },
  { month: 'Mar', price: 110 },
  { month: 'Apr', price: 130 },
  { month: 'May', price: 140 },
  { month: 'Jun', price: 135 },
  { month: 'Jul', price: 150 },
  { month: 'Aug', price: 160 },
  { month: 'Sep', price: 155 },
  { month: 'Oct', price: 170 },
  { month: 'Nov', price: 180 },
  { month: 'Dec', price: 190 },
]

export default function PriceAnalysis() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  })

  return (
    <section ref={ref} className="py-20 px-4 sm:px-6 lg:px-8 bg-gray-50">
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={inView ? { opacity: 1, y: 1 } : {}}
        transition={{ duration: 0.8 }}
      ><motion.h2
        className="text-3xl sm:text-4xl font-bold text-center text-gray-900 mb-12"
        initial={{ opacity: 0, y: 50 }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.8 }}
      >
        Advanced Pricing Analysis
      </motion.h2>
        <Card className="w-full max-w-4xl mx-auto">
          <CardHeader>
            <CardTitle>Price Analysis Over Time</CardTitle>
            <CardDescription>Track the price changes of a sample product over the past year</CardDescription>
          </CardHeader>
          <CardContent>
            <ChartContainer
              config={{
                price: {
                  label: "Price",
                  color: "hsl(var(--chart-1))",
                },
              }}
              className="w-full"
            >
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <ChartTooltip content={<ChartTooltipContent />} />
                  <Legend />
                  <Line type="monotone" dataKey="price" stroke="var(--color-price)" name="Price" />
                </LineChart>
              </ResponsiveContainer>
            </ChartContainer>
          </CardContent>
        </Card>
      </motion.div>
    </section>
  )
}

