'use client'
import React from 'react'
import { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import ProductSearch from './components/ProductSearch.tsx'
import ProductList from './components/ProductList.tsx'
import ProductDetails from './components/ProductDetails.tsx'
import Pagination from './components/pagination.tsx'
import { Product } from './types.tsx'
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Navigation.tsx'


interface HomeProps {
  setIsAuthenticated: (value: boolean) => void;
}

export default function Home({ setIsAuthenticated }: HomeProps) {
  const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('authToken')
        if (!token) {
          setError('No authentication token found. Please log in.')
          navigate('/login')
        }
      }, [navigate])

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  })

  const [searchQuery, setSearchQuery] = useState('')
  const [products, setProducts] = useState<Product[]>([])
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const itemsPerPage = 15

useEffect(() => {
  const token = localStorage.getItem('authToken')
  if (!token) {
    setError('No authentication token found. Please log in.')
  }
}, [])

const handleProductSelect = (product: Product) => {
    const index = currentItems.findIndex(p => p === product);
    setCurrentIndex(index);
    setSelectedProduct(product);
  };

  useEffect(() => {
    const fetchProducts = async () => {
      if (!searchQuery) return
  
      setIsLoading(true)
      setError(null)
  
      try {
        const token = localStorage.getItem('authToken')
        if (!token) {
          throw new Error('No authentication token found')
        }
  
        console.log('Fetching products with search query:', searchQuery)
  
        const response = await fetch('https://lex0.com/search/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ search_term: searchQuery })
        })
  
        if (!response.ok) {
          if (response.status === 401) {
            setError('Authentication failed. Please log in again.')
            localStorage.removeItem('authToken')
          } else {
            throw new Error('Failed to fetch products')
          }
        }
  
        const data = await response.json()
        
        // Check if response is a single product or array
        const productsArray = Array.isArray(data) ? data : [data]
        setProducts(productsArray)
        
        // If single product, automatically open product details
        if (!Array.isArray(data)) {
          setSelectedProduct(data)
        }
        
      } catch (err) {
        setError('Failed to load products. Please try again later.')
        console.error('Error fetching products:', err)
      } finally {
        setIsLoading(false)
      }
    }
  
    fetchProducts()
  }, [searchQuery])

const indexOfLastItem = currentPage * itemsPerPage
const indexOfFirstItem = indexOfLastItem - itemsPerPage
const currentItems = products.slice(indexOfFirstItem, indexOfLastItem)

const paginate = (pageNumber: number) => setCurrentPage(pageNumber)


return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white relative">
      <Header setIsAuthenticated={setIsAuthenticated} />
      <motion.div
        ref={ref}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-6"
        initial={{ opacity: 0, y: 20 }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.8 }}
      >
        <h1 className="text-2xl font-bold text-gray-900 mb-2">Product Lookup</h1>
        <ProductSearch onSearch={setSearchQuery} isLoading={false} />
        {isLoading && <p className="text-center text-gray-600 mt-8">Loading products...</p>}
        {error && <p className="text-center text-red-600 mt-8">{error}</p>}
        {!isLoading && !error && products.length > 0 && (
          <>
            <ProductList
              products={currentItems}
              onProductSelect={setSelectedProduct}
            />
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={products.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </>
        )}
        {!isLoading && !error && products.length === 0 && searchQuery && (
          <p className="text-center text-gray-600 mt-8">No products found. Try a different search term.</p>
        )}
      </motion.div>
      {selectedProduct && (
  <ProductDetails
    product={selectedProduct}
    onClose={() => {
      setSelectedProduct(null);
      setCurrentIndex(-1);
    }}
    products={currentItems}
    onProductSelect={handleProductSelect}
    currentIndex={currentIndex}
  />
)}
    </div>
  );
};
