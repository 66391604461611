import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../pages/LandingPage/components/button.tsx';
import { Menu, X, ShoppingCart } from 'lucide-react';

interface NavigationProps {
    setIsAuthenticated: (value: boolean) => void;
}

export default function Navigation({ setIsAuthenticated }: NavigationProps) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();
    
    const handleLogout = () => {
        // Remove the auth token
        localStorage.removeItem('authToken');
        // Update authentication state
        setIsAuthenticated(false);
        // Redirect to landing page
        navigate('/');
    };
    
    return (
        <>
            <div className="h-16" />
            
            <header className="py-4 px-4 sm:px-6 lg:px-8 bg-white/80 backdrop-blur-md fixed top-0 left-0 right-0 z-30">
                <div className="max-w-7xl mx-auto flex justify-between items-center">
                    {/* Left Section */}
                    <div className="flex items-center space-x-8">
                        <Link to="/" className="text-2xl font-bold text-black-600">
                            LEXIOL
                        </Link>
                        <div className="hidden md:flex space-x-8">
                        <Link to="/pricing" className="text-gray-800 hover:text-black transition-colors">
                                Pricing
                            </Link>
                            <Link to="/home" className="text-gray-800 hover:text-black transition-colors">
                                Search
                            </Link>
                
                            <Link to="/ask" className="text-gray-800 hover:text-black transition-colors">
                                Symptom Search
                            </Link>
                        </div>
                    </div>

                    {/* Right Section */}
                    <div className="hidden md:flex items-center space-x-4">
                        <Link 
                            to="/cart" 
                            className="p-2 text-gray-800 hover:text-black transition-colors rounded-full hover:bg-gray-100"
                        >
                            <ShoppingCart size={20} />
                        </Link>
                        <div className="w-px h-6 bg-gray-200" /> {/* Vertical divider */}
                        <Button variant="ghost" onClick={handleLogout}>
                            Log Out
                        </Button>
                    </div>

                    {/* Mobile Menu Button */}
                    <button
                        className="md:hidden text-black"
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                    >
                        {isMenuOpen ? <X /> : <Menu />}
                    </button>
                </div>

                {/* Mobile Menu */}
                {isMenuOpen && (
                    <div className="md:hidden mt-4">
                        <nav className="flex flex-col space-y-4">
                        <Link to="/pricing" className="text-gray-800 hover:text-black transition-colors">
                                Pricing
                            </Link>
                            <Link to="/home" className="text-gray-800 hover:text-black transition-colors">
                                Search
                            </Link>
                            <Link to="/ask" className="text-gray-800 hover:text-black">
                                Symptom Search
                            </Link>
                            
                            <Link to="/cart" className="text-gray-800 hover:text-black flex items-center space-x-2">
                                <ShoppingCart size={20} />
                                <span>Cart</span>
                            </Link>
                            <Button variant="ghost" className="w-full" onClick={handleLogout}>
                                Log Out
                            </Button>
                        </nav>
                    </div>
                )}
            </header>
        </>
    );
}