'use client'
import React from 'react'

import { useEffect, useRef } from 'react'

export default function SineWaveBackground({ className = '' }: { className?: string }) {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const canvas = canvasRef.current
    if (!canvas) return

    const ctx = canvas.getContext('2d')
    if (!ctx) return

    let animationFrameId: number

    const resizeCanvas = () => {
      canvas.width = window.innerWidth
      canvas.height = window.innerHeight
    }

    const drawSineWaves = (time: number) => {
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.strokeStyle = 'rgba(100, 100, 255, 0.1)'  // More visible blue color
      ctx.lineWidth = 3  // Slightly thinner lines

      const waveCount = 5
      const waveSpacing = canvas.height / (waveCount + 1)

      for (let i = 0; i < waveCount; i++) {
        ctx.beginPath()
        for (let x = 0; x < canvas.width; x++) {
          const y = Math.sin((x + time) * 0.01 + i) * 30 + waveSpacing * (i + 1)
          ctx.lineTo(x, y)
        }
        ctx.stroke()
      }

      animationFrameId = requestAnimationFrame((t) => drawSineWaves(t * 0.05))
    }

    resizeCanvas()
    drawSineWaves(0)

    window.addEventListener('resize', resizeCanvas)

    return () => {
      window.removeEventListener('resize', resizeCanvas)
      cancelAnimationFrame(animationFrameId)
    }
  }, [])

  return <canvas ref={canvasRef} className={`fixed inset-0 w-full h-full ${className}`} />
}

