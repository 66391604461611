'use client'
import React from 'react'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { Button } from "./button.tsx"
import { Link } from 'react-router-dom'

export default function CTA() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  })

  return (
    <section ref={ref} className="py-20 px-4 sm:px-6 lg:px-8 bg-blue-600">
      <div className="max-w-3xl mx-auto text-center">
        <motion.h2
          className="text-3xl sm:text-4xl font-bold text-white mb-6"
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.8 }}
        >
          Ready to streamline your pharmacy operations?
        </motion.h2>
        <motion.p
          className="text-xl text-blue-100 mb-8"
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          Join Lexiol today and experience faster price checks, easier staff onboarding, and smarter product recommendations.
        </motion.p>
        <motion.div
  initial={{ opacity: 0, y: 50 }}
  animate={inView ? { opacity: 1, y: 0 } : {}}
  transition={{ duration: 0.8, delay: 0.4 }}
>
  <Link to="/demo">
  <motion.div
  style={{ position: 'relative', display: 'inline-block' }}
  initial={{ opacity: 0, y: 50 }}
  animate={inView ? { opacity: 1, y: 0 } : {}}
  transition={{ duration: 0.8, delay: 0.4 }}
>
  <Link to="/demo">
    <Button size="lg" className="bg-white text-blue-600 hover:bg-blue-50">
      Get Started
    </Button>
  </Link>
</motion.div>

  </Link>
</motion.div>

      </div>
    </section>
  )
}

