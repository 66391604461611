import React from 'react';
import { Product } from '../../Home/types.tsx';
import ProductImage from './ProductImage.tsx';

interface RecommendationProps {
  item: string;
  reason: string;
  products: Product[];
  onProductSelect: (product: Product) => void;
}

export function Recommendation({ item, reason, products, onProductSelect }: RecommendationProps) {
  return (
    <div className="mb-8">
      <h2 className="text-2xl font-bold mb-2">{item}</h2>
      <p className="text-gray-700 mb-4">{reason}</p>
      <div className="flex space-x-4 overflow-x-auto pb-4">
        {products.map((product) => (
          <div
            key={product.upc}
            className="flex-shrink-0 cursor-pointer"
            onClick={() => onProductSelect(product)}
          >
            <div className="bg-white rounded-lg shadow-md overflow-hidden w-[172px]">
              <ProductImage
                images={product.images}
                alt={product.title}
              />
              <div className="p-3">
                <p className="text-sm text-center break-words">{product.title}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

interface RecommendationsProps {
  recommendations: {
    item: string;
    description: string;
    products: Product[];
  }[];
  onProductSelect: (product: Product) => void;
}

export default function Recommendations({ recommendations, onProductSelect }: RecommendationsProps) {
  return (
    <div>
      {recommendations.map((recommendation, index) => (
        <Recommendation
          key={index}
          reason={recommendation.description}
          {...recommendation}
          onProductSelect={onProductSelect}
        />
      ))}
    </div>
  );
}