import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

interface PricingItemProps {
  product: any;
  quantity: number;
  onUpdate: (upc: string, updates: any) => void;
  onRemove: (upc: string) => void;
  onSelect: (product: any) => void;
}

const PricingItem = ({
  product,
  quantity,
  onUpdate,
  onRemove,
  onSelect
}: PricingItemProps) => {
  const [price, setPrice] = useState(() => {
    console.log('Initial new_price:', product.new_price);
    return product.new_price || '';
  });

  useEffect(() => {
    console.log('Effect new_price:', product.new_price);
    setPrice(product.new_price || '');
  }, [product.new_price]);

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrice(e.target.value);
  };

  const handlePriceBlur = (e: React.FocusEvent) => {
    e.stopPropagation();
    if (price !== '') {
      onUpdate(product.upc, { new_price: Number(price) });
    }
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    onUpdate(product.upc, { quantity: Number(e.target.value) });
  };

  const handleRemoveClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onRemove(product.upc);
  };

  const handleRowClick = (e: React.MouseEvent) => {
    // Only trigger onSelect if the click wasn't on an interactive element
    if (!(e.target as HTMLElement).closest('input, button')) {
      onSelect(product);
    }
  };

  return (
    <motion.tr
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, x: -50 }}
      transition={{ duration: 0.3 }}
      className="hover:bg-gray-50 cursor-pointer"
      onClick={handleRowClick}
    >
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="w-16 h-16 flex items-center justify-center">
          {product.images && product.images.length > 0 ? (
            <img
              src={product.images[0]}
              alt={product.title}
              className="h-16 w-16 object-contain"
            />
          ) : (
            <div className="h-16 w-16 bg-gray-200 flex items-center justify-center text-gray-500">
              N/A
            </div>
          )}
        </div>
      </td>
      <td className="px-6 py-4 whitespace-normal break-words">
        <div className="space-y-1">
          <div className="font-medium">{product.title}</div>
          <div className="text-sm text-gray-500">Item #: {product.item_number}</div>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        {product.brand || 'Unknown'}
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <input
          type="number"
          min="1"
          value={quantity}
          onChange={handleQuantityChange}
          className="w-16 text-center border border-gray-300 rounded"
        />
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <input
          type="number"
          min="0"
          step="0.01"
          value={price}
          onChange={handlePriceChange}
          onBlur={handlePriceBlur}
          className="w-24 text-center border border-gray-300 rounded mr-2"
          placeholder="Enter price"
        />
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <button
          onClick={handleRemoveClick}
          className="px-3 py-1 text-sm bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
        >
          Remove
        </button>
      </td>
    </motion.tr>
  );
};

export default PricingItem;