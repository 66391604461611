import Features from './components/Features.tsx'
import SymptomSearch from './components/SymptomSearch.tsx'
import PriceAnalysis from './components/PriceAnalysis.tsx'
import PredictionCard from './components/PredictionCard.tsx'
import Hero from './components/Hero.tsx'
import CTA from './components/CTA.tsx'
import Header from './components/Header.tsx'
import React from 'react'

export default function LandingPage() {
  return (
    <main className="min-h-screen bg-gradient-to-b from-blue-50 to-white relative">
      <Header />
      <Hero />
      <Features />
      <SymptomSearch />
      <PriceAnalysis />
      <PredictionCard />
      <CTA />
    </main>
  )
}