import React, { useState, useRef } from 'react';

const ProductImage = ({ images, alt }: { images: string[], alt: string }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);

  const isShldDomain = (url: string) => {
    // Check if the URL contains c.shld.net anywhere in the path
    return url.includes('c.shld.net');
  };

  const checkImage = () => {
    if (imgRef.current) {
      const img = imgRef.current;
      const currentUrl = images[currentImageIndex];
      
      // Wait a short moment for the image to fully render
      setTimeout(() => {
        // Check if image is the "No Image Available" placeholder
        if (isShldDomain(currentUrl) && 
            img.naturalWidth === 251 && 
            img.naturalHeight === 251) {
          handleError();
        } else {
          setImageLoaded(true);
        }
      }, 100);
    }
  };

  const handleError = () => {
    setImageLoaded(false);
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  const handleLoad = () => {
    checkImage();
  };

  return (
    <div className="w-16 h-16 relative flex items-center justify-center">
      {currentImageIndex < images.length ? (
        <>
          <img
            ref={imgRef}
            src={images[currentImageIndex]}
            alt={alt}
            className={`max-h-16 max-w-16 w-auto h-auto object-contain ${
              !imageLoaded ? 'opacity-0' : 'opacity-100'
            }`}
            onError={handleError}
            onLoad={handleLoad}
          />
          {!imageLoaded && (
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="w-4 h-4 border-2 border-gray-300 border-t-gray-600 rounded-full animate-spin"></div>
            </div>
          )}
        </>
      ) : (
        <div className="h-16 w-16 bg-gray-200 flex items-center justify-center text-gray-500">
          N/A
        </div>
      )}
    </div>
  );
};

export default ProductImage;